import getImageDimensions from "utilities/getImageDimensions"
import Image from "./Image"
import cn from "classnames"
import storyblokImageLoader from "utilities/storyblokImageLoader"

export default function ImageHeading({ headingImage, fallback, className, centered = false }) {
  return (
    <>
      {headingImage?.filename && (
        <div className={cn("flex", className)}>
          <div className="pt-4 pb-8 w-full max-h-36">
            <Image
              className={cn("h-auto my-0 max-h-full", { "mx-auto": centered })}
              height={getImageDimensions("height", headingImage.filename)}
              width={getImageDimensions("width", headingImage.filename)}
              src={headingImage.filename}
              loader={storyblokImageLoader}
              style={{ height: "auto" }}
              alt={headingImage.alt || fallback}
            />
          </div>
        </div>
      )}
      <h2 className={headingImage?.filename ? `sr-only` : `uppercase mb-1 font-semibold tracking-wider`}>{fallback}</h2>
    </>
  )
}
